<template>
  <div class="container">
    <div class="card-list">
      <template v-for="game in games" :key="game.project">
        <div class="card" v-for="(ranking, index) in game.ranking_list" :key="index" @click="viewRaning(game.project)">
          <div class="game-logo">
            <img :src="game.logo" alt="" />
          </div>
          <div class="game-name">
            <span>{{ ranking }}</span>
          </div>
          <div class="arrow-box"><van-icon name="arrow" /></div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: 'GameRanking',
}
</script>
<script setup>
import { Icon } from 'vant'
import { onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { gameList } from '@/api/gameData'

const VanIcon = Icon
const store = useStore()
const router = useRouter()

onMounted(() => {
  initData()
})

let games = reactive({})

const initData = () => {
  store.dispatch('user/getInfo')
  store.dispatch('player/getInfo')
  gameList().then(res => {
    res.data.games.forEach(game => {
      if (game.show_ranking) {
        games[game.project] = game
      }
    })
  })
}

const viewRaning = project => {
  router.push('/game-ranking/' + project)
}
</script>

<style lang="scss" scoped>
.card-list {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top: 20px;
}

.card {
  box-sizing: border-box;
  margin-bottom: 20px;
  width: 90vw;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 20px 50px 0 rgba(0, 0, 0, 0.1);
  background: #2d2d35;
  display: flex;
}

.game-logo {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  border: 2px solid #fff;
}

.game-logo img {
  width: 60px;
  height: 60px;
}

.game-name {
  box-sizing: border-box;
  padding: 0 10px;
  font-size: 15px;
  height: 60px;
  line-height: 60px;
  color: #fff;
  font-weight: 600;
  width: 100%;
}
.game-name span {
  display: inline-block;
  vertical-align: middle;
  line-height: 20px;
}
.arrow-box {
  color: #7c8197;
  height: 60px;
  line-height: 60px;
  font-size: 24px;
}
</style>
